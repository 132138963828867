<template>
  <div class="congratulations-page-wrapper">
    <questionnaire-header
      :user-info="patientInfo"
      :doctor-info="doctorInfo"
      :language="locale"
      :language-options="languageOptions"
      :progress="progress"
      :time-left="0"
      @change-language="onChangeLanguage"
    />

    <div class="congratulations-page">
      <div class="congratulations-page__heading page-heading">
        <div class="page-heading__dysfunction-colors dysfunction-colors">
          <span
            v-for="color of dysfunctionsColorList"
            :key="color"
            class="dysfunction-colors__color"
            :style="{ 'background-color': color }"
          ></span>
        </div>

        <h1
          class="page-heading__title u-typography-helvetica u-text-display u-text-display--s q-mb16"
        >
          {{ $t('congratulationPage.title') }}
        </h1>
        <p class="u-typography-helvetica u-text u-text--m q-mb24">
          {{ $t('congratulationPage.introText') }}
        </p>
      </div>

      <div
        class="congratulations-page__doctor-profile doctor-profile page-section u-typography-helvetica"
      >
        <div class="doctor-profile-photo">
          <img class="doctor-photo" :src="$options.defaultDoctorPhoto" alt="doctor photo" />
          <div class="doctor-signature ">{{ doctorFullName }}</div>
        </div>

        <div class="doctor-profile-data">
          <p class="doctor-profile-data__doctor-name">{{ doctor.name }}</p>

          <div class="doctor-profile-data__doctor-contacts doctor-contacts">
            <div class="doctor-contacts__contact contact-field">
              <img :src="$options.phoneIcon" class="contact-field__icon" />
              <span class="profile-field__text">{{ doctor.doctorOfficePhone }}</span>
            </div>
            <div class="doctor-contacts__contact contact-field">
              <img :src="$options.emailIcon" class="contact-field__icon" />
              <a
                :href="doctorEmail"
                class="profile-field__text contact-field__text--email email-field flex"
              >
                <span class="email-field__sub-text">
                  {{ splitedEmail[0] }}
                </span>
                <span>@{{ splitedEmail[1] }}</span>
              </a>
            </div>
            <div
              v-if="doctor.personalPage"
              class="doctor-contacts__contact contact-field u-typography-gilroy"
            >
              <img :src="$options.websiteIcon" class="contact-field__icon" />
              <a
                :href="doctor.personalPage"
                target="_blanc"
                class="contact-field__text contact-field__text--link"
              >
                {{ $t('congratulationPage.label.website') }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="congratulations-page__diagnostic-section page-section">
        <h2 class="page-section__title u-typography-helvetica u-text u-text--l">
          {{ $t('congratulationPage.meetYourDiagnostic.title') }}
        </h2>

        <p class="u-typography-helvetica u-text u-text--m">
          {{ $t('congratulationPage.meetYourDiagnostic.text') }}
        </p>

        <div class="spider-chart-container u-typography-helvetica u-text u-text--xs">
          <spider-chart :dysfunctions="dysfunctions" />
        </div>
      </div>

      <div
        v-if="shouldDisplayPrioritySection"
        class="congratulations-page__your-priorities-section your-priorities-section page-section"
      >
        <h2 class="page-section__title u-typography-helvetica u-text u-text--l">
          {{ $t('congratulationPage.yourProrities.title') }}
        </h2>

        <div class="your-priorities-section__priorities-list priorities-list">
          <div
            v-for="{ title, color, priority } of dysfunctionsPrioritylist"
            :key="title"
            class="priorities-list__priority priority"
          >
            <div class="priority__color-container priority-color-container">
              <span
                class="priority-color-container__color"
                :style="{ 'background-color': color }"
              ></span>
              <span class="priority-color-container__content">{{ priority.level }}</span>
            </div>

            <span class="priority__title"> {{ $t(title) }}</span>
          </div>
        </div>
      </div>

      <div class="congratulations-page__questions questions">
        <h2 class="page-section__title u-typography-helvetica u-text u-text--l">
          {{ $t('congratulationPage.section.FAQ') }}
        </h2>

        <div
          v-for="{ questionNumber, title, questionAnswer } of questionsList"
          :key="questionNumber"
          class="question-block"
          @click="toggleQuestion(questionNumber)"
        >
          <div class="question">
            <span class="question__title u-typography-helvetica u-text u-text--m">{{
              $t(title)
            }}</span>
            <span class="toggle-icon" :class="getToggleIconClass(questionNumber)"></span>
          </div>

          <div
            v-if="getIsOpenedQuestion(questionNumber)"
            class="question__answer u-typography-helvetica u-text u-text--s"
          >
            <i18n tag="div" :path="questionAnswer">
              <a
                href="https://www.universkin.com"
                target="_blank"
                place="Universkin"
                class="question__answer--link"
                >Universkin</a
              >
              <a
                :href="doctorQuestionnaireLink"
                target="_blank"
                place="link"
                class="question__answer--link"
                >{{ $t('congratulationsPage.creatingNewDiagnostic') }}</a
              >

              <span place="phone" class="bold">{{ doctor.doctorOfficePhone }}</span>
              <span place="email" class="bold">{{ doctor.doctorEmail }}</span>
            </i18n>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { SpiderChart } from 'universkin-shared';
import { spiderChartDysfunctionsMixin } from 'universkin-shared/src/commonDomainObjects/mixins/spiderChartDysfunctionsMixin';

import QuestionnaireHeader from '@/modules/questionnaire/new-design-components/QuestionnaireHeader';

import {
  redirectPatientAfterQuestionnaire,
  clearLocalStorage
} from '@/modules/questionnaire/utils';
import { doctorAndPatientInfoMixin } from '@/modules/questionnaire/mixins/doctorAndPatientInfoMixin';
import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';

import { types } from '@/modules/questionnaire/store/types';

import phoneIcon from '@/modules/questionnaire/assets/images/phone.svg';
import emailIcon from '@/modules/questionnaire/assets/images/envelope.svg';
import websiteIcon from '@/modules/questionnaire/assets/images/globe.svg';
import defaultDoctorPhoto from '@/assets/images/doctor/default-doctor-image.svg';

const QUESTIONS_DOCTOR_LEVEL_1 = [
  {
    questionNumber: 1,
    title: 'congratulationsPage.question.whatHappenNext',
    questionAnswer: 'congratulationsPage.answer.whatHappenNext.doctorLevelOne'
  },
  {
    questionNumber: 2,
    title: 'congratulationsPage.question.doINeedToMake',
    questionAnswer: 'congratulationsPage.answer.doINeedToMake.doctorLevelOne'
  },
  {
    questionNumber: 3,
    title: 'congratulationsPage.question.whatIfMyDoctor',
    questionAnswer: 'congratulationsPage.answer.whatIfMyDoctor.doctorLevelOne'
  },
  {
    questionNumber: 4,
    title: 'congratulationsPage.question.canIChangeResult',
    questionAnswer: 'congratulationsPage.answer.canIChangeResult.doctorLevelOne'
  },
  {
    questionNumber: 5,
    title: 'congratulationsPage.question.canIOrder',
    questionAnswer: 'congratulationsPage.answer.canIOrder.doctorLevelOne'
  }
];

const QUESTIONS_DOCTOR_LEVEL_2 = [
  {
    questionNumber: 1,
    title: 'congratulationsPage.question.whatHappenNext',
    questionAnswer: 'congratulationsPage.answer.whatHappenNext.doctorLevelTwo'
  },
  {
    questionNumber: 2,
    title: 'congratulationsPage.question.doINeedToMake',
    questionAnswer: 'congratulationsPage.answer.doINeedToMake.doctorLevelTwo'
  },
  {
    questionNumber: 3,
    title: 'congratulationsPage.question.whatIfMyDoctor',
    questionAnswer: 'congratulationsPage.answer.whatIfMyDoctor.doctorLevelTwo'
  },
  {
    questionNumber: 4,
    title: 'congratulationsPage.question.canIChangeResult',
    questionAnswer: 'congratulationsPage.answer.canIChangeResult.doctorLevelTwo'
  },
  {
    questionNumber: 5,
    title: 'congratulationsPage.question.canIOrder',
    questionAnswer: 'congratulationsPage.answer.canIOrder.doctorLevelTwo'
  }
];

export const DYSFUNCTION_COLORS = {
  'oxidative-stress': '#ECCA19',
  'skin-appearance': '#E7948A',
  'skin-redness': '#D22C2B',
  'skin-dryness': '#367EBF',
  'skin-oliness': '#D66541',
  'skin-texture': '#2A2529',
  pimples: '#027A54',
  'skin-pigmentation': '#391500'
};

const DYSFUNCTION_PRIORITY_CONFIG = {
  low: { value: 1, level: '!' },
  high: { value: 2, level: '!!' },
  very_high: { value: 4, level: '!!!' }
};

export default {
  name: 'PageCongratulations',
  components: {
    QuestionnaireHeader,
    SpiderChart
  },
  phoneIcon,
  emailIcon,
  websiteIcon,
  defaultDoctorPhoto,
  mixins: [doctorAndPatientInfoMixin, changeLanguageMixin, spiderChartDysfunctionsMixin],
  data() {
    return {
      openedQuestions: []
    };
  },
  computed: {
    ...mapGetters({
      doctor: types.getters.GET_DOCTOR,
      doctorFullName: types.getters.GET_DOCTOR_FULL_NAME,
      patient: types.getters.GET_PATIENT,
      questionnaire: types.getters.GET_QUESTIONNAIRE,
      scores: types.getters.GET_SCORES,
      areClarificationQuestionsSkipped: types.getters.GET_ARE_CLARIFICATION_QUESTIONS_SKIPPED
    }),
    dysfunctions() {
      return this.getDysfunctionsList(this.scores);
    },
    dysfunctionsColorList() {
      return Object.values(DYSFUNCTION_COLORS);
    },
    context() {
      return this.questionnaire.context;
    },
    progress() {
      return Math.floor((this.context.answeredSteps * 100) / this.context.totalSteps);
    },
    doctorEmail() {
      return `mailto:${this.doctor.doctorEmail}`;
    },
    splitedEmail() {
      return get(this.doctor, 'doctorEmail', '').split('@');
    },
    doctorQuestionnaireLink() {
      return `${process.env.VUE_APP_BASE_URL}diag/${this.doctor.doctorSlug}`;
    },
    dysfunctionsPrioritylist() {
      const {
        fields: {
          acneDiscomfort,
          hyperpigmentationDiscomfort,
          skinAppearanceDiscomfort,
          greasySkinDiscomfort,
          drynessDiscomfort,
          textureDiscomfort,
          inflammationDiscomfort
        }
      } = this.questionnaire;

      const dysfunctionsConfig = [
        {
          title: 'dysfunction.skinAppearance',
          color: DYSFUNCTION_COLORS['skin-appearance'],
          priority: DYSFUNCTION_PRIORITY_CONFIG[skinAppearanceDiscomfort]
        },
        {
          title: 'dysfunction.pimples',
          color: DYSFUNCTION_COLORS.pimples,
          priority: DYSFUNCTION_PRIORITY_CONFIG[acneDiscomfort]
        },
        {
          title: 'dysfunction.drynessAndDehydration',
          color: DYSFUNCTION_COLORS['skin-dryness'],
          priority: DYSFUNCTION_PRIORITY_CONFIG[drynessDiscomfort]
        },
        {
          title: 'dysfunction.skinTexture',
          color: DYSFUNCTION_COLORS['skin-texture'],
          priority: DYSFUNCTION_PRIORITY_CONFIG[textureDiscomfort]
        },
        {
          title: 'dysfunction.skinRedness',
          color: DYSFUNCTION_COLORS['skin-redness'],
          priority: DYSFUNCTION_PRIORITY_CONFIG[inflammationDiscomfort]
        },
        {
          title: 'dysfunction.skinOilness',
          color: DYSFUNCTION_COLORS['skin-oliness'],
          priority: DYSFUNCTION_PRIORITY_CONFIG[greasySkinDiscomfort]
        },
        {
          title: 'dysfunction.skinPigmentation',
          color: DYSFUNCTION_COLORS['skin-pigmentation'],
          priority: DYSFUNCTION_PRIORITY_CONFIG[hyperpigmentationDiscomfort]
        }
      ];

      return dysfunctionsConfig
        .filter(({ priority }) => priority)
        .sort(
          (dysfunction1, dysfunction2) => dysfunction2.priority.value - dysfunction1.priority.value
        );
    },
    questionsList() {
      return this.doctor.hasWebshopAccess ? QUESTIONS_DOCTOR_LEVEL_2 : QUESTIONS_DOCTOR_LEVEL_1;
    },
    shouldDisplayPrioritySection() {
      return this.dysfunctionsPrioritylist.length && !this.areClarificationQuestionsSkipped;
    }
  },
  created() {
    window.addEventListener('popstate', () => this.onPopState());
  },
  beforeMount() {
    document.body.id = 'questionnaire-congrats';
  },
  beforeDestroy() {
    window.removeEventListener('popstate', () => this.onPopState());
  },
  methods: {
    close() {
      redirectPatientAfterQuestionnaire(this.$i18n, this.doctor);

      clearLocalStorage();
    },
    onPopState() {
      history.go(1);
    },
    getIsOpenedQuestion(question) {
      return this.openedQuestions.includes(question);
    },
    toggleQuestion(questionNumber) {
      if (this.getIsOpenedQuestion(questionNumber)) {
        this.openedQuestions = this.openedQuestions.filter(question => question !== questionNumber);

        return;
      }

      this.openedQuestions.push(questionNumber);
    },
    getToggleIconClass(question) {
      return this.getIsOpenedQuestion(question) ? 'icon-minus-gray' : 'icon-plus-gray';
    }
  },
  metaInfo: {
    title: 'Congratulations',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helpers/colors.scss';
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';
@import '~universkin-shared/src/assets/styles/scss/typography-helvetica.scss';
@import '@/assets/css/common/icons';

.congratulations-page-wrapper {
  background-color: $white;
  padding: 0 16px;
}

.congratulations-page {
  width: 328px;
  max-width: 100%;
  margin: 0 auto;
  padding: 57px 0;

  &__doctor-profile {
    padding: 24px 16px;
    background-color: #edf2f7;
    border-radius: 10px;
  }

  &__diagnostic-section {
    padding-bottom: 30px;
    border-bottom: 1px solid $color-grey200;
  }

  &__your-priorities-section {
    padding-bottom: 32px;
    border-bottom: 1px solid $color-grey200;
  }
}

.page-section {
  margin-bottom: 32px;

  &__title {
    margin-bottom: 13px;
  }
}

.doctor-profile-data {
  &__doctor-name {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color: $color-grey900;
  }
}

.contact-field {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &__icon {
    display: inline-block;
    margin-right: 16px;
    background-repeat: no-repeat;
  }

  &__text {
    font-size: 14px;

    font-weight: 400;
    line-height: 20px;
    text-align: left;

    &--email {
      color: $color-grey700;
    }

    &--link {
      display: inline-block;
      font-weight: 600;
      text-decoration: underline;
      color: $color-graphite;
    }
  }
}

.email-field {
  &__sub-text {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
  }
}

.doctor-profile-photo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
}

.doctor-photo {
  width: 120px;
  height: 120px;
  border-radius: 165px;
}

.doctor-signature {
  margin-top: 12px;
  font-family: var(--u-font-family-Quentin);
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: $color-graphite;
}

.dysfunction-colors {
  &__color {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 6px;
    border-radius: 5px;
  }
}

.priority {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  &__title {
    font-family: var(--u-font-family-Helvetica);
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4px;
  }
}

.priority-color-container {
  position: relative;

  &__color {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    top: 0;
    left: 0;
    width: 22px;
    height: 20px;

    border-radius: 5px;
  }

  &__content {
    position: absolute;
    display: block;
    width: 22px;
    height: 16px;
    z-index: 10;
    color: $white;
    top: 0;
    text-align: center;
    font-family: var(--font-family-Gilroy);
    font-size: 16px;
    line-height: 22px;
  }
}

.spider-chart-container {
  width: 328px;
  max-width: 100%;
  margin: 24px auto 0;
}

.question-block {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--u-color-grey-200);
  cursor: pointer;
}

.toggle-icon {
  display: block;
  min-width: 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.question {
  display: flex;
  justify-content: space-between;

  &__title {
    margin-right: 20px;
  }

  &__answer {
    padding-top: 20px;
    white-space: pre-wrap;

    &--link {
      color: $color-grey700;
      text-decoration: underline;
    }
  }
}

@media (min-width: $tablet-start) {
  .congratulations-page-wrapper {
    padding: 0 30px;
  }

  .congratulations-page {
    width: 554px;
    max-width: 100%;
    padding: 70px 0;

    &__doctor-profile {
      padding: 32px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 58px;
    }

    &__diagnostic {
      padding-bottom: 43px;
    }

    &__your-priorities-section {
      padding-bottom: 40px;
    }
  }

  .page-section {
    margin-bottom: 40px;

    &__title {
      margin-bottom: 16px;
    }
  }

  .doctor-profile-data {
    justify-content: flex-start;

    &__doctor-name {
      padding-bottom: 16px;
      margin-bottom: 16px;
      line-height: 38px;
      border-bottom: 1px solid $color-grey400;
    }
  }

  .email-field {
    &__sub-text {
      max-width: 200px;
    }
  }

  .doctor-profile-photo {
    margin-bottom: 0;
  }

  .priorities-list {
    max-height: 130px;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
  }

  .spider-chart-container {
    width: 470px;
    height: 410px;
    max-width: 100%;
    margin-top: 32px;
  }
}
</style>
